import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import DishTagCard from '../Dish/Components/DishTagCard';
import DishAddCard from '../Dish/Components/DishAddCard';

const HomepageDish = ({
  title,
  link,
  type,
  subTitle,
  content,
  currentUser,
  needAddCard = false
}) => {
  return (
    <HomepageSection {...{ title, link, type, subTitle }} isMobileScroll isNew>
      <div className={styles.homepageDish}>
        {content.map(({ id, link, title, image, tag, dishesCount }, index) => (
          <DishTagCard
            key={index}
            {...{ id, link, title, image, tag, dishesCount, index, type }}
          />
        ))}
        {needAddCard && <DishAddCard {...currentUser} />}
      </div>
    </HomepageSection>
  );
};

export default HomepageDish;
