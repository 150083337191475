import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import { PostSourceTypes } from './types';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';

const HomepageBlog = ({ title, link, type, content }) => {
  return (
    <HomepageSection title={title} link={link} type={type}>
      {content.map(({ id, link, title: postTitle, image }, index) => (
        <a
          href={link}
          key={id}
          className={styles.homepageBlogItem}
          data-track-id="homepage"
          data-module={title}
          data-position={index + 1}
          data-type={type}
        >
          <figure className={styles.homepageBlogItemImage}>
            <Image
              className={styles.homepageBlogItemImageSource}
              src={imaginaryUrl(image, { width: 400 })}
              alt={postTitle}
            />
            <figcaption className={styles.homepageBlogItemImageCaption}>
              {postTitle}
            </figcaption>
          </figure>
        </a>
      ))}
    </HomepageSection>
  );
};

HomepageBlog.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PostSourceTypes).isRequired
};

export default HomepageBlog;
