import { useEffect } from 'react';
import styles from './style.module.scss';
import HomepageHero from './HomepageHero';
import HomepageChannelEntries from './HomepageChannelEntries';
import HomepageCuration from './HomepageCuration';
import HomepageBlog from './HomepageBlog';
import HomepageMarketProducts from './HomepageMarketProducts';
import HomepagePopularKeywords from './HomepagePopularKeywords';
import HomepageCampaigns from './HomepageCampaigns';
import HomepageBrands from './HomepageBrands';
import HomepageAppDownload from './HomepageAppDownload';
import HomepageDish from './HomepageDish';
import { captureException } from '@sentry/browser';

export const DATA_ERROR = {
  hero: 'Hero',
  pinned: 'Channel entries',
  tag: 'Popular search keywords',
  product: 'Market product',
  auto_latest_post: 'Latest blog posts',
  editor_selected_recipe: 'Editor selected recipes',
  latest_popular_recipe: 'Latest popular recipes',
  latest_recipe: 'Latest recipes'
};

const REQUIRED_STORY_FIELDS = Object.keys(DATA_ERROR).filter(
  (key) => !['hero', 'pinned', 'tag'].includes(key)
);

const deliverDataError = (field) => {
  const error = new Error(`Homepage data issue: ${DATA_ERROR[field]}`);
  error.name = 'HomepageDataError';
  captureException(error);
};

const checkRequiredStories = (stories) => {
  stories.forEach(({ dataType, content }) => {
    if (REQUIRED_STORY_FIELDS.includes(dataType) && !content)
      deliverDataError(dataType);
  });
};

const componentDispatcher = (story, index, currentUser) => {
  const { dataType, content } = story;
  if (!content || !content.length) return null;

  switch (dataType) {
    case 'dishes':
      return <HomepageDish key={index} {...{ ...story, currentUser }} />;
    case 'auto_latest_post':
      return <HomepageBlog key={index} {...story} />;
    case 'product':
      return <HomepageMarketProducts key={index} {...story} />;
    default:
      return (
        <HomepageCuration
          key={index}
          isVideo={dataType.includes('video')}
          {...story}
        />
      );
  }
};

const Homepage = ({
  hero,
  pinned,
  campaign,
  brand,
  stories,
  tag,
  currentUser
}) => {
  useEffect(() => {
    if (!hero) deliverDataError('hero');

    if (!pinned) deliverDataError('pinned');

    if (!tag.content) deliverDataError('tag');

    checkRequiredStories(stories);
  }, [hero, pinned, stories, tag.content]);

  return (
    <main className={styles.homepage}>
      {hero && <HomepageHero {...hero} />}
      {pinned && <HomepageChannelEntries {...pinned} />}
      {!!stories.length && (
        <section className={styles.homepageMain}>
          {stories.map((story, index) =>
            componentDispatcher(story, index, currentUser)
          )}
        </section>
      )}
      <aside className={styles.homepageAside}>
        {campaign && <HomepageCampaigns {...campaign} />}
        {brand && <HomepageBrands {...brand} />}
      </aside>
      {tag.content && <HomepagePopularKeywords {...tag} />}
      <HomepageAppDownload />
    </main>
  );
};
export default Homepage;
